import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import styled from 'styled-components'
import Layout from '../components/Layout'
// markup
const AboutPage = () => {
  // current image styling looks bugged in local dev but is correct when built and deployed
  const StyledImgDiv = styled.div`
  img{
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  @media (max-width:960px){
    img{
        width:30vw;
      }
    }
  `

  return (
    <Layout>
      <h1>About the therapist</h1>
      <StyledImgDiv>
        <StaticImage src="../images/portrait.jpg" alt="christine's portrait" style={{width:"20vw"}}/>
      </StyledImgDiv>
      <p>
        My name is Christine Colavito.  I currently hold my masters degree in mental health counseling from Adelphi University.  
        I am a licensed mental health counselor and credentialed alcohol and substance abuse counselor.  
        I also completed a post graduate certificate program in trauma treatment and studies.  
        I have worked as a therapist in an outpatient setting for 9 years and have worked in the mental health field for over 14 years. 
        I have worked with a variety of different clients with varying issues, including (but not limited to), substance abuse problems, 
        depression, anxiety, panic disorder, bipolar disorder, trauma, PTSD, relationship issues, grief and loss issues and anger issues. 
        <br/>
        <br/>
        Years ago, someone asked me, "what will your life's legacy be?" 
        The answer to that question is why I work in the mental health and substance abuse field. 
        I want my legacy to be making a difference in other people's lives when they need it the most. 
        Not only is being a therapist my career, it is also my life's work.
        <br/>
        <br/>
        I very much look forward to meeting you and working with you in therapy, should I get the privilege.  
      </p>
        
    </Layout>
  )
}

export default AboutPage